import React from "react";
import styled from "styled-components";
import {
  GoogleIcon,
  ICON_ID,
  ICON_SIZES,
} from "../../components/icons/google-icon";
import { useNavigate } from "react-router-dom";

export default function AdminHome() {
  const navigate = useNavigate();

  return (
    <Container>
      <Selection onClick={() => navigate("/admin/approvals")}>
        <GoogleIcon
          id={ICON_ID.Approval}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Approvals
        <SelectionDescription>
          Approve order requests from store managers across all branches.
        </SelectionDescription>
      </Selection>
      <Selection onClick={() => navigate("/supplier")}>
        <GoogleIcon
          id={ICON_ID.Groups}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Manage suppliers
        <SelectionDescription>
          Your supplier management portal. Create new suppliers, update existing
          suppliers and update supplier products.
        </SelectionDescription>
      </Selection>
      <Selection onClick={() => navigate("/admin/department")}>
        <GoogleIcon
          id={ICON_ID.Inventory}
          isNeutral
          size={ICON_SIZES.Large}
        ></GoogleIcon>
        Manage Department
        <SelectionDescription>
          Modify department configuration.
        </SelectionDescription>
      </Selection>
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 items per row */
  gap: 8px; /* Space between items */
  width: 80%;
  margin: auto;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(
      2,
      1fr
    ); /* 2 items per row for smaller screens */
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* 1 item per row for small screens */
  }
`;
const Selection = styled.div`
  box-shadow: ${(props) => props.theme.application.boxShadow};
  border-radius: 3px;
  font-size: 1.2rem;
  flex: 25%;
  border: 2px solid ${(props) => props.theme.application.border};
  padding-top: 20px;
  padding-bottom: 3%;
  font-weight: 600;
  background: ${(props) => props.theme.application.background};
  padding-right: 16px;
  cursor: pointer;
  margin: 5px;
  &:hover {
    opacity: 0.9;
  }
`;
const SelectionDescription = styled.div`
  margin-top: 15px;
  font-size: 1rem;
  padding-left: 15px;
  padding-right: 10px;
  font-weight: 400;
`;
