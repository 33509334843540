import { createTheme } from "@mui/material";
import { AppTheme } from "../../models/theming/app-theme";


const customAppTheme: AppTheme = {
  background: "#ffffff",
  logoPath: "/pakFoods/logo.png",
  logoBackground: "#165F34",
  textContrast: "#ffffff",
  border: "#CBCBCB",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1);",
  text: "#757575",
  link: "#165F34",
  hover: "#e8f8ec",
  header: "#fcfdfe",
  body: "#e8f8ec",
  placeholder: "#F1F1F1",
  scrollBar: "#165F34",
  login: {
    background: "#F8F8F8",
    foreground: "#ffffff",
  },
  navbar: {
    background: "#ffffff",
  },
  scheme: {
    primary: "#56AE57",
    danger: "#FF0000",
    warning: "#f0ad4e",
    success: "#165F34",
    contrast: "#ffffff",
    secondary: "#b35c44",
    neutral: "#757575",
  },
  table: {
    background: "#ffffff",
    hover: "#F8F8F8",
    groupHeader: "#5F1641"
  },
  product: {
    background: "#ffffff",
    productBackground: "#F1F1F1"
  },
  modal: {
    backdrop: "rgba(0, 0, 0, 0.5)",
    background: "#ffffff"
  }
}

declare module '@mui/material/styles' {
  interface Theme {
    application: AppTheme;
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    application?: AppTheme;
  }
}

export const pakFoodsTheme = createTheme({
  components: {
    MuiTimeline: {
      styleOverrides: {
        root: {
          backgroundColor: 'red',
        },
      },
    },
  },
  palette: {
    primary: {
      main: customAppTheme.scheme.primary, // example primary color
      contrastText: '#fff',
    },
    secondary: {
      main: customAppTheme.scheme.secondary, // example secondary color
    },
  },
  application: customAppTheme, // your custom theme properties
});
