import styled from "styled-components";

type AdminListItem = {
  label: string;
  action: () => void;
};
interface AdminSidebarProps {
  items: AdminListItem[];
}

export default function AdminSidebar({ items }: AdminSidebarProps) {
  return (
    <Container>
      {items.map((x) => {
        return (
          <ListItem key={x.label} onClick={x.action}>
            {" "}
            {x.label}
          </ListItem>
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.application.boxShadow};

  @media (max-width: 1600px) {
    flex-direction: row;
    margin-bottom: 10px;
    div {
      border-right: 1px solid ${(props) => props.theme.application.border};
    }
  }

  background: ${(props) => props.theme.application.background};
  border-radius: 5px;
  text-align: center;
  overflow-x: auto;
`;

const ListItem = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${(props) => props.theme.application.border};
  cursor: pointer;
  min-width:50px;

  &:hover {
    background: ${(props) => props.theme.application.table.hover};
  }
`;
