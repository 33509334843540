import { useEffect, useState } from "react";
import styled from "styled-components";
import AdminSidebar from "../../components/layout/admin-sidebar";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { fetchDepartments } from "../../store/features/organisation/department-slice";
import {
  fetchDepartmentById,
  updateDepartment,
  toggleEdit,
  applyNewValues,
  setSelectedCategory,
  setNewValueForTarget,
  setCategoryFields,
  setNewCategory,
} from "../../store/features/organisation/admin-department-slice";
import TextInput from "../../components/controls/text-input";
import Button from "../../components/controls/button";
import { ICON_ID } from "../../components/icons/google-icon";
import { ApplicationDepartment } from "../../models/organisation/application-department";
import { ApplicationDepartmentCategory } from "../../models/organisation/application-department-category";
import ListItemText from "@mui/material/ListItemText";
import { showSuccess } from "../../components/notification/toastr-actions";
import { requiredValidator } from "../../helpers/validators";
import ItemList from "../../components/controls/item-list";
import { ScrollableList } from "../../styles/shared/card";
import EditableField from "../../components/controls/editable-field";
import { EditApplicationDepartment } from "../../models/organisation/edit-application-department";
interface Validation {
  departmentName?: string | null;
  categoryName?: string | null;
  categoryDescription?: string | null;
}

const AdminDepartment = () => {
  const dispatch = useAppDispatch();
  const departments = useAppSelector((state) => state.department.departments);
  const selectedDepartment = useAppSelector(
    (state) => state.adminDepartment.selectedDepartment
  );
  const selectedCategory = useAppSelector(
    (state) => state.adminDepartment.selectedCategory
  );
  const isCreatingCategory = useAppSelector(
    (state) => state.adminDepartment.isCreatingCategory
  );
  const [validation, setValidation] = useState<Validation>({
    departmentName: null,
    categoryName: null,
    categoryDescription: null,
  });

  useEffect(() => {
    dispatch(fetchDepartments()).then((action) => {
      if (
        fetchDepartments.fulfilled.match(action) &&
        action.payload &&
        action.payload.length > 0
      ) {
        const firstDepartment = action.payload[0];
        if (firstDepartment) {
          handleDepartmentSelect(firstDepartment.id);
        }
      }
    });
  }, [dispatch]);

  const handleDepartmentSelect = (departmentId: number) => {
    dispatch(fetchDepartmentById(departmentId));
  };

  const handleSaveCategories = async () => {
    if (!selectedDepartment || !validateCategoryFields()) return;
    const updatedDepartmentCategories =
      selectedDepartment.departmentCategories?.map((category) =>
        category.id === selectedCategory?.id
          ? {
              ...category,
              name: selectedCategory.newName || "",
              description: selectedCategory.newDescription || "",
            }
          : category
      ) || [];
    if (selectedCategory?.id === 0) {
      updatedDepartmentCategories.push({
        ...selectedCategory,
        name: selectedCategory.newName || "",
        description: selectedCategory.newDescription || "",
      });
    }
    const updatedDepartment: EditApplicationDepartment = {
      ...selectedDepartment,
      departmentCategories: updatedDepartmentCategories,
    };
    const response = await dispatch(updateDepartment(updatedDepartment));
    if (response) {
      dispatch(
        showSuccess(
          isCreatingCategory
            ? "New category has been successfully added."
            : "Department categories have been successfully updated."
        )
      );
    }
  };

  const handleSave = async (
    property: keyof ApplicationDepartment,
    value: string,
    field: "Name" | "ProductSelectionMode"
  ) => {
    if (field === "Name") {
      const departmentNameError = requiredValidator(value);
      setValidation((prevState) => ({
        ...prevState,
        departmentName: departmentNameError,
      }));
      if (departmentNameError) {
        return;
      }
    }
    if (selectedDepartment) {
      const updatedDepartment: ApplicationDepartment = {
        ...selectedDepartment,
        [property]: value,
      };
      const response = await dispatch(updateDepartment(updatedDepartment));
      if (response) {
        dispatch(
          showSuccess(
            `Successfully updated the ${
              field === "Name" ? "department name" : "product selection mode"
            }.`
          )
        );
        dispatch(applyNewValues({ field }));
        dispatch(fetchDepartments());
      }
    }
  };

  const handleCategorySelect = (category: ApplicationDepartmentCategory) => {
    dispatch(setSelectedCategory(category));
    resetValidation("Category");
  };

  const handleCreateNewCategory = () => {
    dispatch(setNewCategory());
    resetValidation("Category");
  };

  const handleCloseButtonClick = (
    target: "Department",
    field: "Name" | "ProductSelectionMode",
    value: number | string
  ) => {
    dispatch(toggleEdit({ field }));
    dispatch(setNewValueForTarget({ target, field, value }));
    if (field === "Name") resetValidation(target);
  };

  const onChange = <K extends keyof ApplicationDepartmentCategory>(
    value: ApplicationDepartmentCategory[K],
    target: "Department" | "Category",
    property: "Name" | "Description",
    validation?: (input: ApplicationDepartmentCategory[K]) => string | null
  ) => {
    const errorMessage = validation ? validation(value) : null;
    dispatch(
      setNewValueForTarget({
        target: target,
        field: property,
        value: value,
      })
    );
    setValidation((prevState) => ({
      ...prevState,
      [target.toLowerCase() + property]: errorMessage,
    }));
  };

  const validateCategoryFields = () => {
    const validation = {
      categoryName: requiredValidator(selectedCategory?.newName || ""),
      categoryDescription: requiredValidator(
        selectedCategory?.newDescription || ""
      ),
    };

    setValidation(validation);
    const hasErrors = Object.values(validation).some((error) => error !== null);
    if (hasErrors) {
      return false;
    } else {
      return true;
    }
  };
  const resetValidation = (target: "Department" | "Category") => {
    setValidation((prevState) => ({
      ...prevState,
      ...(target === "Department"
        ? { departmentName: null }
        : { categoryName: null, categoryDescription: null }),
    }));
  };

  const renderDepartmentCategories = () => (
    <DepartmentCategoriesContainer>
      <Header>
        <Title>Department Categories</Title>
        <Button
          variant="text"
          icon={ICON_ID.Add}
          id="btnCreateNewCategory"
          label="Create New"
          fullWidth={false}
          onClick={handleCreateNewCategory}
        />
      </Header>
      <ScrollableList maxHeight="420px">
        {selectedDepartment?.departmentCategories?.map((category) => (
          <ItemList
            key={category.id}
            selected={selectedCategory?.id === category.id}
            onClick={() => handleCategorySelect(category)}
          >
            <ListItemText
              primary={category.name}
              secondary={category.description}
            />
          </ItemList>
        ))}
      </ScrollableList>
    </DepartmentCategoriesContainer>
  );

  const renderCategoryForm = () => (
    <DepartmentCategoriesContainer>
      <Title style={{ marginBottom: "20px" }}>
        {isCreatingCategory ? "Add New Category" : "Edit Department Categories"}
      </Title>

      <TextInput
        label="Category Name"
        id="categoryNameInput"
        value={selectedCategory?.newName || ""}
        error={validation.categoryName}
        onChange={(event) =>
          onChange(event.target.value, "Category", "Name", requiredValidator)
        }
      />
      <TextInput
        label="Category Description"
        id="categoryDescriptionInput"
        value={selectedCategory?.newDescription || ""}
        error={validation.categoryDescription}
        onChange={(event) =>
          onChange(
            event.target.value,
            "Category",
            "Description",
            requiredValidator
          )
        }
      />
      <ButtonWrapper>
        <Button
          variant="outlined"
          id="btnCancel"
          label="Cancel"
          fullWidth={false}
          onClick={() => {
            if (selectedCategory) {
              dispatch(setCategoryFields(selectedCategory));
              resetValidation("Category");
            }
          }}
        />
        <Button
          variant="contained"
          id="btnUpdate"
          label={isCreatingCategory ? "Add" : "Update"}
          fullWidth={false}
          onClick={handleSaveCategories}
        />
      </ButtonWrapper>
    </DepartmentCategoriesContainer>
  );

  return (
    <Container>
      <NavigationSection>
        <AdminSidebar
          items={departments.map((department) => ({
            label: department.name,
            action: () => handleDepartmentSelect(department.id),
          }))}
        ></AdminSidebar>
      </NavigationSection>
      <ContentSection>
        <ContentTitle hasProductSelectionMode={false}>
          <FieldsGridContainer>
            <EditableField
              type="text"
              value={selectedDepartment?.newName || ""}
              isEditing={selectedDepartment?.editName || false}
              error={validation.departmentName}
              label="Department Name"
              displayValue={selectedDepartment?.name || "Select a department"}
              onChange={(value) =>
                onChange(value, "Department", "Name", (input) =>
                  requiredValidator(input)
                )
              }
              onSave={() =>
                handleSave("name", selectedDepartment?.newName || "", "Name")
              }
              onCancel={() =>
                handleCloseButtonClick(
                  "Department",
                  "Name",
                  selectedDepartment?.name || ""
                )
              }
              onEdit={() => dispatch(toggleEdit({ field: "Name" }))}
            />
          </FieldsGridContainer>
        </ContentTitle>

        <ContentTitle hasProductSelectionMode={true}>
          <FieldsGridContainer>
            <EditableField
              type="dropdown"
              isEditing={selectedDepartment?.editProductSelectionMode || false}
              value={selectedDepartment?.newProductSelectionMode || ""}
              displayValue={`Product Selection Mode: ${selectedDepartment?.productSelectionMode}`}
              options={[
                { value: "Default", label: "Default" },
                { value: "Continous", label: "Continous" },
              ]}
              label="Product Mode"
              onChange={(value) =>
                dispatch(
                  setNewValueForTarget({
                    target: "Department",
                    field: "ProductSelectionMode",
                    value: value,
                  })
                )
              }
              onSave={() =>
                handleSave(
                  "productSelectionMode",
                  selectedDepartment?.newProductSelectionMode || "",
                  "ProductSelectionMode"
                )
              }
              onCancel={() =>
                handleCloseButtonClick(
                  "Department",
                  "ProductSelectionMode",
                  selectedDepartment?.productSelectionMode || ""
                )
              }
              onEdit={() =>
                dispatch(toggleEdit({ field: "ProductSelectionMode" }))
              }
            />
          </FieldsGridContainer>
        </ContentTitle>

        <CategoriesWrapper>
          {renderDepartmentCategories()}
          <VerticalDivider />
          {renderCategoryForm()}
        </CategoriesWrapper>
      </ContentSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  margin: 10px;
  column-gap: 20px;
  @media (max-width: 1600px) {
    flex-direction: column;
  }
`;

const ContentTitle = styled.div<{ hasProductSelectionMode: boolean }>`
  font-size: 1.2rem;
  font-weight: 500;
  border-bottom: 2px solid
    ${(props) =>
      props.hasProductSelectionMode
        ? "none"
        : props.theme.application.scheme.primary};
  padding: 5px 0px 5px 5px;
  margin-bottom: ${(props) =>
    props.hasProductSelectionMode ? "1rem" : "1rem"};
`;

const NavigationSection = styled.div`
  min-width: 15%;
`;

const ContentSection = styled.div`
  display: flex;
  box-shadow: ${(props) => props.theme.application.boxShadow};
  flex-direction: column;
  width: 100%;
  padding: 8px;
  background: ${(props) => props.theme.application.background};
  border-radius: 4px;
  height: 100%;
`;

const CategoriesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  width: 100%;
  position: relative;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  border-top: 1px solid ${(props) => props.theme.application.border};
  padding: 10px;

  height: 100%;
  height: -moz-available;
  height: -webkit-fill-available;
  height: fill-available;
`;

const VerticalDivider = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 1px;
  background-color: ${(props) => props.theme.application.border};
  margin-left: -1px;
  @media (max-width: 768px) {
    display: none;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
`;
const FieldsGridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;
const DepartmentCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 10px;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
  color: ${(props) => props.theme.application.text};
`;
export default AdminDepartment;
