import { Alert, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { setTheme } from '../../store/features/theming/theme-selector-slice';
import styled from 'styled-components';
import { ThemeConstants } from '../../constants/theme-constants';
import TextInput from '../../components/controls/text-input';
import Button from '../../components/controls/button';
import PasswordInput from '../../components/controls/password-input';
import { LargeSpacer, MediumSpacer } from '../../styles/shared/spacing';
import BrandLogo from '../../components/brand-logo';
import { authenticate, setEmail, setErrors, setPassword } from '../../store/features/authentication/login-slice';
import AnchorLink from '../../components/controls/anchor-link';
import { RootState } from '../../store/store';
import { emailValidator, requiredValidator } from '../../helpers/validators';
import { useNavigate } from 'react-router-dom';
import { useChangeTheme } from '../../hooks/useChangeTheme';
import { ApplicationRole } from '../../models/authentication/application-roles';

export default function Login() {
    const userState = useAppSelector((state => state.user))
    const email = useAppSelector((state: RootState) => state.login.email);
    const password = useAppSelector((state: RootState) => state.login.password);
    const errors = useAppSelector((state: RootState) => state.login.errors);
    const loading = useAppSelector((state: RootState) => state.login.loading);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const changeTheme = useChangeTheme();

    // log the user in if the user object has been set.
    useEffect(() => {
        if (userState.user) {
            if (userState.user.role == ApplicationRole.SupplierManager) {
                navigate("/supplier");
            } else {
                navigate("/");
            }
        }
    }, [userState])


    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(setPassword(event.target.value));
        dispatch(setErrors({ ...errors, password: requiredValidator(value) }));
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        dispatch(setEmail(event.target.value));
        dispatch(setErrors({ ...errors, email: emailValidator(value) }));
    };

    const handleSubmit = async () => {
        const emailError = emailValidator(email);
        const passwordError = requiredValidator(password);
        if (emailError || passwordError) {
            dispatch(setErrors({ email: emailError, password: passwordError, pageError: null }));
        } else {
            dispatch(authenticate());
        }
    };

    return (
        <Background>
            <LoginContainer>
                <LoginForm>
                    <LogoContainer>
                        <BrandLogo size="large" />
                        <AppTitle>Ordering Portal</AppTitle>
                    </LogoContainer>
                    <LargeSpacer />
                    {errors.pageError && <Alert severity="error">{errors.pageError}</Alert>}
                    <TextInput id='txtEmail' label='Email' error={errors.email} onChange={handleEmailChange} value={email} />
                    <MediumSpacer />
                    <PasswordInput id='txtPassword' onChange={handlePasswordChange} error={errors.password} value={password} label='Password' />
                    <LargeSpacer />
                    <ExtraLinks>
                        <AnchorLink id="btnForgotPassword" href='/forgot-password' label='Forgot Passsword?' />
                    </ExtraLinks>
                    <LargeSpacer />
                    <LargeSpacer />
                    <Button id="btnLogin" variant="contained" label='Login' fullWidth loading={loading} isPrimary onClick={handleSubmit} />
                </LoginForm>
            </LoginContainer>
        </Background>
    )
}

const Background = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.application.login.background};
    height: 100vh;
    width: 100vw;
`;

const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => props.theme.application.login.foreground};
    border-radius: 8px;
    box-shadow: ${props => props.theme.application.boxShadow};
    width: 270px;
    padding: 40px;    


`;



const LoginForm = styled.div`
    width: 100%;
    text-align: center;
`;

const LogoContainer = styled.div`
    background: ${props => props.theme.application.logoBackground}; 
    padding:10px;
    border-radius:8px;
    box-shadow: ${props => props.theme.application.boxShadow};   
`
const AppTitle = styled.div`
    color: ${props => props.theme.application.textContrast}; 
    font-size:1.5rem;
`

const ExtraLinks = styled.div`
display:flex;
align-items: start;
font-size:0.8rem;
`



