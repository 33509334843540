import { useEffect, useState } from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import { RootState } from '../../store/store';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { getLoggedInUser } from '../../store/features/authentication/user-slice';
import Layout from '../../pages/layouts/layout';
import DepartmentLayout from '../../pages/layouts/department-layout';
import useAuth from '../../hooks/useAuth';
import { setSelectedDepartment } from '../../store/features/organisation/department-slice';
import { ApplicationDepartment } from '../../models/organisation/application-department';
import { resetProductPage } from '../../store/features/product/product-slice';
import { ApplicationRole } from '../../models/authentication/application-roles';

export const DepartmentRoute = () => {
    const department = useAppSelector((state: RootState) => state.department);
    const { departmentParam } = useParams();
    const dispatch = useAppDispatch();
    const { initialized, loading, authenticated, role } = useAuth();

    useEffect(() => {
        if (departmentParam && department.departments.length > 0) {
            const foundDepartment: ApplicationDepartment | undefined = department.departments.find(
                x => x.friendlyUrl === departmentParam
            );
            if (foundDepartment) {
                dispatch(setSelectedDepartment(foundDepartment));
                dispatch(resetProductPage());
            }

        }
    }, [departmentParam, department.departments]);


    if (!initialized || loading) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return <Navigate to="/login" replace />
    }

    if (!department.selectedDepartment) {
        return <Navigate to="/home" replace />
    }

    if (role == ApplicationRole.SupplierManager) {
        return <Navigate to="/supplier" replace />;
    }

    return (
        <DepartmentLayout>
            <Outlet />
        </DepartmentLayout>
    )
};