import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import styled from "styled-components";
import { ActiveArea, ContentArea, HeaderArea } from "../styles/shared/page";
import { useAppSelector } from "../hooks/useAppSelector";
import { useAppDispatch } from "../hooks/useAppDispatch";
import { setTab } from "../store/features/pages/home-page-slice";
import { HomeTabConstants } from "../constants/home-tab-constants";
import TableView, { ColumnConfig } from "../components/controls/table-view";
import { GoogleIcon, ICON_ID } from "../components/icons/google-icon";
import Button from "../components/controls/button";
import {
  fetchActiveOrders,
  fetchDeliveredOrders,
} from "../store/features/ordering/order-slice";
import { useNavigate } from "react-router-dom";
import { TableContainer } from "../styles/shared/table";

export default function Home() {
  const homeState = useAppSelector((state) => state.home);
  const orderState = useAppSelector((state) => state.order);
  const branch = useAppSelector((state) => state.branch).selectedBranch;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    dispatch(setTab(newValue));
  };

  const fetchMoreData = () => {
    dispatch(fetchActiveOrders(branch!.id));
  };
  const fetchDeliveredData = () => {
    dispatch(fetchDeliveredOrders(branch!.id));
  };
  const columnConfig: { [key: string]: ColumnConfig } = {
    id: {
      hidden: true,
    },
    displayName: {
      order: 1,
      name: "Order",
      colspan: 2,
      render: (value: string) => (
        <div>
          <strong>{value}</strong>
        </div>
      ),
    },
    lineCount: {
      name: "Items",
      hideOnMobile: true,
      order: 2,
      render: (value: number) => <div>{value} products</div>,
    },
    branch: {
      render: (value: string) => (
        <div>
          <GoogleIcon id={ICON_ID.Domain} />
          {value}
        </div>
      ),
    },
    created: {
      hideOnMobile: true,
      render: (value: Date) => (
        <div>
          {new Date(value).toLocaleString("en-gb", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </div>
      ),
    },
  };

  const handleActionClick = (row: any) => {
    navigate(`/order/${row.id}`);
  };

  const renderActionButton = (row: any) => (
    <Button
      variant="outlined"
      id={row.Name}
      label="View"
      isPrimary={true}
      fullWidth={false}
      onClick={(event) => {
        event.stopPropagation();
        handleActionClick(row);
      }}
    />
  );

  return (
    <>
      <HeaderArea>
        <TabArea>
          <Tabs
            value={homeState.tab}
            onChange={handleChange}
            textColor="primary"
            indicatorColor="primary"
          >
            <Tab value={HomeTabConstants.activeOrders} label="Active Orders" />
            <Tab value={HomeTabConstants.orderHistory} label="Order History" />
          </Tabs>
        </TabArea>
      </HeaderArea>
      <AboveFold>
        <ContentArea>
          <ActiveArea>
            <TableContainer>
              {homeState.tab == HomeTabConstants.activeOrders && (
                <TableView
                  id="orderTable"
                  emptyText="There are no active orders"
                  fetchMoreData={fetchMoreData}
                  onRowClick={handleActionClick}
                  totalCount={orderState.activeOrders?.count ?? 0}
                  data={orderState.activeOrders?.orders ?? []}
                  actionButton={renderActionButton}
                  columnConfig={columnConfig}
                  showHeaders={true}
                />
              )}

              {homeState.tab == HomeTabConstants.orderHistory && (
                <TableView
                  id="orderHistoryTable"
                  emptyText="There are no orders history"
                  fetchMoreData={fetchDeliveredData}
                  onRowClick={handleActionClick}
                  totalCount={orderState.deliveredOrders?.count ?? 0}
                  data={orderState.deliveredOrders?.orders ?? []}
                  actionButton={renderActionButton}
                  columnConfig={columnConfig}
                  showHeaders={true}
                />
              )}
            </TableContainer>
          </ActiveArea>
        </ContentArea>
      </AboveFold>
    </>
  );
}

const AboveFold = styled.div`
  height: 89%;
`;

const TabArea = styled.div`
  padding: 20px;
`;
