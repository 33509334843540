import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { FeatureCard, FeatureCardColumn, FeatureCardRow, FeatureCardTitle } from '../../styles/shared/card';
import Button from '../../components/controls/button';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchSupplierProduct, insertSupplierProduct, resetSearchProducts, searchSupplierProducts, updateSupplierProduct } from '../../store/features/supplier/supplier-product-slice';
import { ApplicationSupplierProductDetail } from '../../models/supplier/application-supplier-product-detail';
import TextInput from '../../components/controls/text-input';
import Checkbox from '../../components/controls/checkbox';
import CurrencyInput from '../../components/controls/currency-input';
import { ApplicationProduct } from '../../models/product/application-product';
import SearchDropdown from '../../components/controls/search-dropdown';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ApplicationError } from '../../models/errors/application-error';
import Image from '../../components/controls/image';
import { fetchProduct } from '../../store/features/product/product-slice';
import { ApplicationProductDetail } from '../../models/product/application-product-detail';
import { ProductContainer, ProductImage, ProductNameContainer, ProductTitle } from '../../styles/shared/product';
import { showSuccess } from '../../components/notification/toastr-actions';
import { closeModal } from '../../store/features/modal/modal-slice';
import { minValueValidator, requiredValidator } from '../../helpers/validators';
import { SmallTextContainer } from '../../styles/shared/table';
import { ApplicationSizeUnit } from '../../models/product/application-size-unit';
import SizeInput from '../../components/controls/size-input';
import { Alert } from '@mui/material';
import { currencyFormatter } from '../../helpers/formatters';

interface SupplierProductModalProps {
    id: number
}

interface Validation {
    name: string | null,
    size: string | null,
    price: string | null,
}

export default function SupplierProductModal({ id }: SupplierProductModalProps) {
    const dispatch = useAppDispatch();
    const supplierProductState = useAppSelector(state => state.supplierProducts);
    const supplierState = useAppSelector(state => state.supplier);
    const [unitPrice, setUnitPrice] = useState<number>(0)
    const [validation, setValidation] = useState<Validation>({
        name: null,
        size: null,
        price: null
    });

    const [product, setProduct] = useState<ApplicationSupplierProductDetail>({
        id: 0,
        supplierId: supplierState.selectedSupplier?.id ?? 0,
        name: "",
        productId: null,
        description: null,
        image: "",
        size: 0,
        sizeUnit: ApplicationSizeUnit.Unknown,
        plu: "",
        updated: new Date(),
        active: true,
        price: null,
        created: new Date()
    });


    const [mappedProduct, setMappedProduct] = useState<ApplicationProductDetail>({
        id: 0,
        name: "",
        description: "",
        image: "",
        plu: "",
        information: {},
        suggestedQuantity: 0,
    });


    const loadProductMapping = (productId: number) => {
        if (productId > 0) {
            dispatch(fetchProduct(productId)).then((response) => {
                var product = response.payload as ApplicationProductDetail;
                setMappedProduct(product)
            })
        }
    }

    useEffect(() => {
        if (id)
            dispatch(fetchSupplierProduct(id)).then((response) => {
                const product = response.payload as ApplicationSupplierProductDetail
                if (product) {
                    setProduct(product);
                    loadProductMapping(product?.productId ?? 0);
                }
            })
    }, [id])


    useEffect(() => {

        if (product.price && product.sizeUnit && product.size) {
            if (product.sizeUnit == ApplicationSizeUnit.KG) {
                setUnitPrice(product.price / product.size)
            } else {
                setUnitPrice(product.price);
            }
        }
    }, [product.size, product.sizeUnit, product.price])



    const onChange = <K extends keyof ApplicationSupplierProductDetail>(
        value: ApplicationSupplierProductDetail[K],
        property: K,
        validation?: (input: ApplicationSupplierProductDetail[K]) => string | null) => {
        const errorMessage = validation ? validation(value) : null;

        setProduct(prevState => ({
            ...prevState,
            [property]: value,
        }));

        setValidation(prevState => ({
            ...prevState,
            [property]: errorMessage,
        }));

    };


    const fetchProducts = async (query: string): Promise<void> => {
        if (!supplierState.selectedSupplier) {
            throw new ApplicationError("No selected supplier");
        }

        await dispatch(searchSupplierProducts({ supplierId: supplierState.selectedSupplier?.id, searchText: query, fetchAll: true }));
    };

    const isProductEqual = (option: ApplicationProduct, value: ApplicationProduct) => option.id == value.id;

    const renderProductOption = (props: React.HTMLProps<HTMLLIElement>, option: ApplicationProduct) => (
        <StyledOption  {...props}>
            <OptionImage src={option.image} alt={option.name} />
            <OptionText>
                <OptionName>{option.name}</OptionName>
                <OptionSize>Plu: {option.plu}</OptionSize>
            </OptionText>
        </StyledOption>
    );

    const onAutocompleteChange = (value: ApplicationProduct | null) => {
        onChange(value?.id ?? 0, "productId");
        loadProductMapping(value?.id ?? 0);
    }


    const updateProduct = async () => {

        if (!validateAndCheckErrors()) {
            return;
        }

        var notificationMessage = `${product.name} `

        if (product.id > 0) {
            await dispatch(updateSupplierProduct(product));
            notificationMessage += "updated";
        } else {
            await dispatch(insertSupplierProduct(product));
            notificationMessage += "created";
        }


        dispatch(showSuccess(notificationMessage));
        dispatch(closeModal());
    }

    const validateAndCheckErrors = () => {
        const validation = {
            name: requiredValidator(product.name),
            size: requiredValidator(product.size),
            price: minValueValidator(product.price)
        };

        setValidation(validation);

        const hasErrors = Object.values(validation).some(error => error !== null);

        if (hasErrors) {
            return false; // Or handle the errors as needed
        } else {
            return true; // Proceed with form submission or further processing
        }
    };

    const handleSizeChange = (newValue: { number: number; unit: ApplicationSizeUnit }) => {
        onChange(newValue.number, "size");
        onChange(newValue.unit, "sizeUnit");
    };

    return (
        <Container>
            <Title>
                {product.id == 0 ? "Create new product" : <>Edit {product.name}</>}
                <Active>
                    <Checkbox id="cbActive"
                        checked={product.active}
                        onChange={(event) => onChange(event.target.checked, "active")}
                        label='Active'></Checkbox>
                </Active>
            </Title>
            <FeatureCard>
                <FeatureCardTitle>
                    Product information
                </FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <TextInput
                            id="txtProductName"
                            value={product.name}
                            label="Name"
                            onChange={(event) => onChange(event.target.value, "name", (input) => requiredValidator(input))}
                            error={validation.name}
                        ></TextInput>
                    </FeatureCardColumn>
                    <FeatureCardColumn>
                        <SizeInput
                            id="supplier-product-size"
                            label="Size"
                            valueNumber={product.size}
                            valueUnit={product.sizeUnit}
                            onChange={(newValue: { number: number; unit: ApplicationSizeUnit }) => {
                                onChange(newValue.number, "size")
                                onChange(newValue.unit, "sizeUnit");
                            }}
                            error={validation.size} />

                    </FeatureCardColumn>
                </FeatureCardRow>
            </FeatureCard>
            <FeatureCard>
                <FeatureCardTitle>
                    Mapping
                </FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <SearchDropdown<ApplicationProduct>
                            fetchOptions={fetchProducts}
                            clearOptions={() => dispatch(resetSearchProducts())}
                            options={supplierProductState.searchProducts?.products ?? []}
                            label="Search products"
                            onChange={onAutocompleteChange}
                            isOptionEqualToValue={isProductEqual}
                            renderOption={renderProductOption}
                        />
                    </FeatureCardColumn>
                </FeatureCardRow>

                <FeatureCardRow>
                    <FeatureCardColumn>
                        {mappedProduct.id > 0 && (
                            <MappedProduct>
                                <ProductContainer>
                                    <ProductImage>
                                        <Image image={mappedProduct.image}></Image>
                                    </ProductImage>
                                    <ProductNameContainer>
                                        <ProductTitle>{mappedProduct.name}</ProductTitle>
                                        <div>{mappedProduct.description} </div>
                                        <SmallTextContainer>Plu: {mappedProduct.plu}</SmallTextContainer>
                                    </ProductNameContainer>
                                </ProductContainer>
                            </MappedProduct>
                        )}
                    </FeatureCardColumn>
                </FeatureCardRow>
            </FeatureCard>

            <FeatureCard>
                <FeatureCardTitle>
                    Price
                </FeatureCardTitle>
                <FeatureCardRow>
                    <FeatureCardColumn>
                        <CurrencyInput value={product.price}
                            onChange={(event) => onChange(parseFloat(event.target.value), "price", (input) => minValueValidator(input))}
                            error={!!validation.price} helperText={validation.price}
                        ></CurrencyInput>
                        <Alert severity="info"><strong>{currencyFormatter.format(unitPrice)}</strong> per {product.sizeUnit}</Alert>
                    </FeatureCardColumn>

                </FeatureCardRow>
            </FeatureCard>

            <ButtonContainer>
                <Button id="btnCreate" label={product.id == 0 ? "Insert" : "Update"} variant="contained" onClick={updateProduct} ></Button>
            </ButtonContainer>
        </Container >

    )
}

const Container = styled.div`
    display:flex;
    flex-direction:column;
`

const Title = styled.div`
    text-align:center;
    font-size:1.5rem;    
    margin-bottom:30px;
    padding-bottom:10px;
    border-bottom: 3px solid ${(props) => props.theme.application.scheme.primary};    
`

const Active = styled.div`
    float:right;
`

const ButtonContainer = styled.div`
display:flex;
margin-top:10px;
    justify-content: flex-end;

    button:first-of-type{
        margin-right:10px;
    }
}

`

const StyledOption = styled.li`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    &:hover {
        background-color: #f0f0f0;
    }
`;

const OptionImage = styled.img`
    width: 40px;
    height: 40px;
    margin-right: 16px;
    border-radius: 4px;
`;

const OptionText = styled.div`
    display: flex;
    font-size: 0.8rem;
    flex-direction: column;
`;

const OptionName = styled.span`
    font-weight: bold;
    margin-bottom: 4px;
`;

const OptionSize = styled.span`
    color: #666;
`;

const MappedProduct = styled.div`
    margin-top:20px;
`