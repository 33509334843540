import React from "react";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";

interface ItemListProps {
  children: React.ReactNode;
  mx?: number;
  my?: number;
  p?: number;
  onClick?: () => void;
  selected?: boolean;
}
const ItemList: React.FC<ItemListProps> = ({
  children,
  mx,
  my,
  p,
  onClick,
  selected,
}) => {
  return (
    <>
      <StyledListItem
        selected={selected}
        sx={{ my: my, mx: mx, p: p }}
        onClick={onClick}
      >
        {children}
      </StyledListItem>
      <Divider />
    </>
  );
};
const StyledListItem = styled(ListItem)<{ selected?: boolean }>`
  cursor: pointer;
  &:hover {
    background-color: ${(props) =>
      props.theme.application.hover}; // Hover color
  }
  background-color: ${(props) =>
    props.selected
      ? props.theme.application.hover
      : "transparent"}; // Selected color
`;

export default ItemList;
