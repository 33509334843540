import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker as MuiTimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextField } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { PickerChangeHandlerContext, TimeValidationError } from '@mui/x-date-pickers';
import 'dayjs/locale/en-gb';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc)

interface TimePickerProps {
    id: string;
    label: string;
    value: Dayjs | null;
    onChange?: (value: dayjs.Dayjs | null, context: PickerChangeHandlerContext<TimeValidationError>) => void;
    error?: string | null;
    disabled?: boolean;
}

export default function TimePicker({ id, label, value, onChange, error, disabled }: TimePickerProps) {

    return (
        <LocalizationProvider adapterLocale='en-gb' dateAdapter={AdapterDayjs}>
            <MuiTimePicker
                key={id}
                label={label}
                slotProps={{ textField: { variant: "outlined", error: !!error, helperText: error } }}
                disabled={disabled}
                value={value}
                onChange={onChange}
                ampm={false}

            />
        </LocalizationProvider>
    );
}
