import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import OrderService from "../../../services/order/order-service";
import { ApplicationOrderList } from "../../../models/order/application-order-list";
import { ApplicationError } from "../../../models/errors/application-error";
import { ApplicationOrder } from "../../../models/order/application-order";
import { showSuccess } from "../../../components/notification/toastr-actions";
import { resetBasket } from "./basket/basket-slice";
import { ApplicationOrderLineList } from "../../../models/order/application-order-line-list";
import { ApplicationOrderLineUpdate } from "../../../models/order/application-order-line-update";
export const fetchActiveOrders = createAsyncThunk<
  ApplicationOrderList | null,
  number,
  { state: RootState }
>("order/activeOrders", async (branchId, { getState, rejectWithValue }) => {
  const state = getState();
  const orderService = new OrderService();
  try {
    var currentPosition: number =
      state.order.activeOrders?.currentPosition ?? 0;
    var fetchNext: number = 15;
    var count: number = state.order.activeOrders?.count ?? -1;

    if (currentPosition != count) {
      return await orderService.get(branchId, currentPosition, fetchNext);
    }
    return null;
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});
export const fetchDeliveredOrders = createAsyncThunk<
  ApplicationOrderList | null,
  number,
  { state: RootState }
>("order/deliveredOrders", async (branchId, { getState, rejectWithValue }) => {
  const state = getState();
  const orderService = new OrderService();
  try {
    var currentPosition: number =
      state.order.deliveredOrders?.currentPosition ?? 0;
    var fetchNext: number = 15;
    var count: number = state.order.deliveredOrders?.count ?? -1;

    if (currentPosition != count) {
      return await orderService.getDelivered(
        branchId,
        currentPosition,
        fetchNext
      );
    }
    return null;
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const createOrder = createAsyncThunk<
  ApplicationOrder,
  number,
  { state: RootState }
>("order/create", async (basketId, { dispatch, rejectWithValue }) => {
  const orderService = new OrderService();
  try {
    var order = await orderService.create(basketId);
    dispatch(showSuccess(`${order.displayName} created`));
    dispatch(resetBasket());
    return order;
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const fetchOrderLines = createAsyncThunk<
  ApplicationOrderLineList | null,
  number,
  { state: RootState }
>("viewOrder/Lines", async (orderId, { getState, rejectWithValue }) => {
  const state = getState();
  const orderService = new OrderService();
  try {
    var currentPosition: number = state.order.orderLines?.currentPosition ?? 0;
    var fetchNext: number = 15;
    var count: number = state.order.orderLines?.count ?? -1;

    if (currentPosition != count) {
      return await orderService.getOrderLines(
        orderId,
        currentPosition,
        fetchNext
      );
    }
    return null;
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

export const fetchOrder = createAsyncThunk<
  ApplicationOrder,
  number,
  { state: RootState }
>("viewOrder/order", async (orderId, { getState, rejectWithValue }) => {
  const orderService = new OrderService();
  try {
    return await orderService.getById(orderId);
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});
export const generatePDF = createAsyncThunk<Blob, number, { state: RootState }>(
  "pdf/generate",
  async (orderId, { rejectWithValue }) => {
    const pdfService = new OrderService();
    try {
      const pdfBlob = await pdfService.getOrderPDF(orderId);
      return pdfBlob;
    } catch (error: any) {
      const apiError = ApplicationError.handleApiError(error, {});
      return rejectWithValue(apiError);
    }
  }
);
export const updateOrderline = createAsyncThunk<
  ApplicationOrderLineList | null,
  { orderId: number; orders: ApplicationOrderLineUpdate[] },
  { state: RootState }
>("order/updateSupplier", async ({ orderId, orders }, { rejectWithValue }) => {
  const orderService = new OrderService();
  try {
    const response = await orderService.updateOrder(orderId, orders);
    return response;
  } catch (error: any) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError);
  }
});

interface OrderState {
  activeOrders: ApplicationOrderList | null;
  deliveredOrders: ApplicationOrderList | null;
  loading: boolean;
  deliveryMode: boolean;
  order: ApplicationOrder | null;
  orderLines: ApplicationOrderLineList | null;
}
const initialState: OrderState = {
  activeOrders: null,
  deliveredOrders: null,
  loading: false,
  deliveryMode: false,
  order: null,
  orderLines: null,
};

const createOrderSlice = createSlice({
  name: "createOrder",
  initialState,
  reducers: {
    resetActiveOrders: (state) => {
      state.activeOrders = initialState.activeOrders;
    },
    resetOrder: (state) => {
      state.order = initialState.order;
      state.orderLines = initialState.orderLines;
    },
    toggleDeliveryMode: (state) => {
      state.deliveryMode = !state.deliveryMode;
    },
    setNewValue: (
      state,
      action: PayloadAction<{
        productId: number;
        field: "quantityReceived" | "deliveryNotes";
        value: number | string;
      }>
    ) => {
      const orderLine = state.orderLines?.orderLines.find(
        (line) => line.productId === action.payload.productId
      );
      if (orderLine) {
        (orderLine as any)[action.payload.field] = action.payload.value;
      }
    },
    resetChanges: (state) => {
      if (state.orderLines) {
        state.orderLines.orderLines = state.orderLines.orderLines.map(
          (line) => {
            const { quantityReceived, deliveryNotes } =
              extractOrderLineDetails(line);
            return {
              ...line,
              quantityReceived,
              deliveryNotes,
            };
          }
        );
      }
      state.deliveryMode = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchActiveOrders.fulfilled,
        (state, action: PayloadAction<ApplicationOrderList | null>) => {
          state.deliveryMode = false;
          if (action.payload) {
            if (state.activeOrders?.orders) {
              state.activeOrders.orders = state.activeOrders.orders.concat(
                action.payload!.orders
              );
              state.activeOrders.currentPosition =
                action.payload!.currentPosition;
              state.activeOrders.count = action.payload!.count;
            } else {
              state.activeOrders = action.payload;
            }
          }
        }
      )
      .addCase(
        fetchDeliveredOrders.fulfilled,
        (state, action: PayloadAction<ApplicationOrderList | null>) => {
          if (action.payload) {
            if (state.deliveredOrders?.orders) {
              state.deliveredOrders.orders =
                state.deliveredOrders.orders.concat(action.payload!.orders);
              state.deliveredOrders.currentPosition =
                action.payload!.currentPosition;
              state.deliveredOrders.count = action.payload!.count;
            } else {
              state.deliveredOrders = action.payload;
            }
          }
        }
      )
      .addCase(createOrder.pending, handlePending)
      .addCase(createOrder.rejected, handleError)
      .addCase(
        createOrder.fulfilled,
        (state, action: PayloadAction<ApplicationOrder>) => {
          if (state.activeOrders?.orders) {
            state.activeOrders.count += 1;
            state.activeOrders.orders.unshift(action.payload);
            state.activeOrders.currentPosition += 1;
          } else {
            state.activeOrders = {
              orders: [action.payload],
              count: 1,
              currentPosition: 1,
            };
          }

          state.loading = false;
        }
      )
      .addCase(
        fetchOrderLines.fulfilled,
        (state, action: PayloadAction<ApplicationOrderLineList | null>) => {
          if (action.payload) {
            const newOrderLines = action.payload.orderLines.map((line) => {
              const { quantityReceived, deliveryNotes } =
                extractOrderLineDetails(line);
              return {
                ...line,
                quantityReceived,
                deliveryNotes,
              };
            });
            if (state.orderLines?.orderLines) {
              state.orderLines.orderLines =
                state.orderLines.orderLines.concat(newOrderLines);
              state.orderLines.currentPosition = action.payload.currentPosition;
              state.orderLines.count = action.payload.count;
            } else {
              state.orderLines = {
                orderLines: newOrderLines,
                count: action.payload.count,
                currentPosition: action.payload.currentPosition,
              };
            }
            state.deliveryMode = false;
          }
        }
      )
      .addCase(
        fetchOrder.fulfilled,
        (state, action: PayloadAction<ApplicationOrder>) => {
          state.order = action.payload;
        }
      )
      .addCase(
        updateOrderline.fulfilled,
        (state, action: PayloadAction<ApplicationOrderLineList | null>) => {
          if (action.payload) {
            const updatedOrderLines = action.payload.orderLines.map((line) => {
              const { quantityReceived, deliveryNotes } =
                extractOrderLineDetails(line);
              return {
                ...line,
                quantityReceived,
                deliveryNotes,
              };
            });
            if (state.orderLines?.orderLines) {
              state.orderLines.orderLines = updatedOrderLines;
              state.orderLines.count = action.payload.count;
              state.orderLines.currentPosition = action.payload.currentPosition;
            }
            state.deliveryMode = false;
          }
        }
      );
  },
});

const handlePending = (state: OrderState) => {
  state.loading = true;
};

const handleError = (state: OrderState) => {
  state.loading = false;
};
const extractOrderLineDetails = (line: any) => {
  const quantityReceived = line.notes.match(/(\d+) units received/)?.[1] || "0";
  const deliveryNotes = line.notes.match(/Delivery notes: (.+)/)?.[1] || "";

  return {
    quantityReceived,
    deliveryNotes,
  };
};
export const {
  resetOrder,
  resetActiveOrders,
  toggleDeliveryMode,
  setNewValue,
  resetChanges,
} = createOrderSlice.actions;
export default createOrderSlice.reducer;
