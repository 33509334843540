import React from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import Header from '../../components/layout/header';
import styled from 'styled-components';
import Back from '../../components/layout/back';
import { ActiveArea, ContentArea, HeaderArea, Main, Title, TitleArea, VerticalContent } from '../../styles/shared/page';
import ModalHandler from '../modals/modal-handler';
import Toastr from '../../components/notification/toastr';
import { useErrorDisplay } from '../../hooks/useErrorDisplay';
import ConfirmModal from '../../components/notification/confirm-modal';
import { Chip } from '@mui/material';
import { sortDays } from '../../models/supplier/application-day';


interface LayoutProps {
  children: React.ReactNode;
}

const SupplierLayout: React.FC<LayoutProps> = ({ children }) => {
  const user = useAppSelector((state => state.user)).user
  const supplier = useAppSelector((state => state.supplier)).selectedSupplier

  useErrorDisplay();

  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <ConfirmModal></ConfirmModal>
      <Header name={user?.name} team={user?.role} />
      <VerticalContent>
        <HeaderArea>
          <Back></Back>
          <TitleArea>
            <Title>
              {supplier?.name ?? "Suppliers"}

              {supplier && (
                <Badges>
                  {[...supplier.deliveryDays].sort(sortDays).map(x => <Chip key={`chip_${x}`} color="primary" size="small" variant="outlined" label={x} />)}
                </Badges>
              )}
            </Title>
          </TitleArea>
        </HeaderArea>
        <Main>
          <ContentArea>
            <ActiveArea>
              {children}
            </ActiveArea>
          </ContentArea>
        </Main>
      </VerticalContent>
    </div>
  );
};

export default SupplierLayout;


const Badges = styled.div`
margin-left:20px;

div {
  margin-right:10px;
}

display:none;
@media (min-width: 760px) {
  display:inline;
}
`


