import * as React from "react";
import styled from "styled-components";
import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import useScreenSize from "../../hooks/useScreenSize";

const CurrencyInput: React.FC<TextFieldProps> = (props) => {
  return (
    <StyledInput
      type="number"
      variant="outlined"
      InputProps={{
        inputMode: 'numeric',
        startAdornment: <InputAdornment position="start">£</InputAdornment>,
      }}
      {...props}
    />
  );
};

const StyledInput = styled(TextField)`
  .MuiInputBase-root {
    outline: 0;
    font-family: inherit;
    .MuiInputAdornment-root {
      p {
        color: ${(props) => props.theme.application.scheme.primary};
      }
    }
  }
`;
export default CurrencyInput;
