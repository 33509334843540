import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import SupplierService from "../../../services/supplier/supplier-service";
import { RootState } from "../../store";
import { ApplicationProductUpload } from "../../../models/supplier/application-product-upload";
import { ApplicationError } from "../../../models/errors/application-error";

interface SupplierProductUploadState {
  products: ApplicationProductUpload[];
  loading: boolean;
  error: string | null;
}

const initialState: SupplierProductUploadState = {
  products: [],
  loading: false,
  error: null,
};

export const bulkUploadProducts = createAsyncThunk<
  ApplicationProductUpload | null,
  {
    supplierId: number;
    products: ApplicationProductUpload[];
  },
  { state: RootState }
>("supplierProductUpload/bulkUpload", async (payload, { rejectWithValue }) => {
  const supplierService = new SupplierService();

  try {
    const response = await supplierService.bulkUploadProducts(
      payload.supplierId,
      payload.products
    );
    return response;
  } catch (error) {
    const apiError = ApplicationError.handleApiError(error, {});
    return rejectWithValue(apiError || "Failed to upload products");
  }
});

const supplierProductUploadSlice = createSlice({
  name: "supplierProductUpload",
  initialState,
  reducers: {
    storeProcessedRecords(
      state,
      action: PayloadAction<ApplicationProductUpload[] | null>
    ) {
      if (action.payload) {
        state.products = action.payload.map((product) => ({
          ...product,
          editPrice: false,
          newPrice: product.price,
          newSize: product.size,
          newSizeUnit: product.sizeunit,
        }));
      } else {
        state.products = [];
      }
    },
    removeProductByName(state, action: PayloadAction<string>) {
      const index = state.products.findIndex(
        (product) => product.name === action.payload
      );

      if (index !== -1) {
        state.products.splice(index, 1);
      }
    },
    toggleEdit: (
      state,
      action: PayloadAction<{ name: string; field: "Price" | "Size" | "SizeUnit" }>
    ) => {
      const { name, field } = action.payload;

      const product = state.products.find((p) => p.name === name);

      if (product) {
        product[`edit${field}`] = !product[`edit${field}`];
      }
    },

    setNewValue: (
      state,
      action: PayloadAction<{
        name: string;
        field: "Price" | "Size" | "SizeUnit";
        value: number | string;
      }>
    ) => {
      const product = state.products.find(
        (p) => p.name === action.payload.name
      );

      if (product) {
        const fieldMap = {
          Price: "newPrice",
          Size: "newSize",
          SizeUnit: "newSizeUnit",
        };
        const field = fieldMap[action.payload.field];
        (product as any)[field] = action.payload.value;
      }
    },
    applyNewValues: (
      state,
      action: PayloadAction<{ names: string; field: "Price" | "Size" | "SizeUnit" }>
    ) => {
      const product = state.products.find(
        (product) => product.name === action.payload.names
      );

      if (product) {
        const fieldMap = {
          Price: { value: "newPrice", edit: "editPrice", original: "price" },
          Size: { value: "newSize", edit: "editSize", original: "size" },
          SizeUnit: { value: "newSizeUnit", edit: "editSizeUnit", original: "sizeunit" },
        };

        const field = fieldMap[action.payload.field];

        (product as any)[field.original] = (product as any)[field.value];
        (product as any)[field.edit] = false;
      }
    },
    resetProductState(state) {
      state.products = initialState.products;
      state.loading = initialState.loading;
      state.error = initialState.error;
    },
  },
});

export const {
  storeProcessedRecords,
  removeProductByName,
  setNewValue,
  toggleEdit,
  applyNewValues,
  resetProductState,
} = supplierProductUploadSlice.actions;
export default supplierProductUploadSlice.reducer;
