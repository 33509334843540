import styled from "styled-components";

export const TopNavContainer = styled.div`
    width:100%;
    display:flex;
    border-bottom: 1px solid ${props => props.theme.application.border};
    color: ${props => props.theme.application.text};
    background: ${props => props.theme.application.navbar.background};    
    height: 72px;
    gap: 30px;
    justify-content: space-between;   
`
export const SideBar = styled.div`
    display:none;

    @media (min-width: 760px) {
          flex: 0 0 230px; 
        height:100%;
        box-shadow: 8px 2px 11px rgba(0, 0, 0, 0.1);      
        display:flex;
        flex-direction:column;
        font-size:1.1rem;    
        z-index:2;
    }
  
  
`

export const HeaderArea = styled.div`
margin-bottom:10px;
  background: ${props => props.theme.application.header}; 
`

export const ContentArea = styled.div`
  background: ${props => props.theme.application.body}; 
  width: 100%;    
  height: 100%;

`

export const ActiveArea = styled.div`
   height: 90%;
   padding:10px;
    
`


export const VerticalContent = styled.div`
  display: flex;
  flex-direction:column;  
  color: ${props => props.theme.application.text};   
  height:93%;

  main{
  height:90%;
  }
`

export const Content = styled.div`
  display: flex;
  color: ${props => props.theme.application.text};     
  height: calc(100% - 72px);          
`

export const Main = styled.main`
  height:100%;
flex:1;
`

export const TitleArea = styled.div`
  padding: 10px 30px;
`
export const Title = styled.h2`
    margin-bottom: 0px;
    font-size: 2rem;
    margin-top: 0px;
    button{
    margin-left:30px;
    }
`