import { mapStringToEnum } from "../../helpers/enum-helper";
import { ApplicationError } from "../../models/errors/application-error";
import { ApplicationDay } from "../../models/supplier/application-day";
import { ApplicationSupplierDetail } from "../../models/supplier/application-supplier-detail";
import { ApplicationSupplierHeader } from "../../models/supplier/application-supplier-header";
import { ApplicationSupplierList } from "../../models/supplier/application-supplier-list";
import { ApplicationSupplierProductDetail } from "../../models/supplier/application-supplier-product-detail";
import { ApplicationSupplierProductHeader } from "../../models/supplier/application-supplier-product-header";
import { ApplicationSupplierProductsList } from "../../models/supplier/application-supplier-product-list";
import ISupplierService from "./supplier-service-interface";
import client from "../../external/open-api";
import { components } from "../../external/schema/order-link-api-schema";
import { getTicks } from "../../helpers/formatters";
import { ApplicationProductUpload } from "../../models/supplier/application-product-upload";
import { ApplicationSizeUnit } from "../../models/product/application-size-unit";
export default class SupplierService implements ISupplierService {
  async getSupplier(supplierId: number): Promise<ApplicationSupplierDetail> {
    const { data, error } = await client.GET(
      "/v{version}/api/supplier/{supplierId}",
      {
        params: {
          path: { version: "1", supplierId },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch supplier");
    }

    return this.mapToSupplierDetail(data.data ?? {});
  }

  async getSuppliers(
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean
  ): Promise<ApplicationSupplierList> {
    const { data, error } = await client.GET("/v{version}/api/supplier", {
      params: {
        path: { version: "1" },
        query: {
          "Paging.CurrentPosition": currentPosition,
          "Paging.FetchNext": fetchNext,
          "Paging.FetchAll": fetchAll,
        },
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch suppliers");
    }

    return {
      suppliers: this.mapToSupplierHeaders(data.data.suppliers ?? []),
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async insertSupplier(
    supplier: ApplicationSupplierDetail
  ): Promise<ApplicationSupplierDetail> {
    const supplierDto = this.mapToSupplierDTO(supplier);
    const { data, error } = await client.POST("/v{version}/api/supplier", {
      params: {
        path: { version: "1" },
      },
      body: {
        ...supplierDto,
      },
    });

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to insert supplier");
    }

    return this.mapToSupplierDetail(data.data ?? {});
  }

  async updateSupplier(
    supplier: ApplicationSupplierDetail
  ): Promise<ApplicationSupplierDetail> {
    const supplierDto = this.mapToSupplierDTO(supplier);

    const { data, error } = await client.PUT(
      "/v{version}/api/supplier/{supplierId}",
      {
        params: {
          path: { version: "1", supplierId: supplier.id },
        },
        body: {
          ...supplierDto,
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to update supplier");
    }

    return this.mapToSupplierDetail(data.data ?? {});
  }

  async getProduct(
    supplierProductId: number
  ): Promise<ApplicationSupplierProductDetail> {
    const { data, error } = await client.GET(
      "/v{version}/api/supplier/supplierproduct/{supplierProductId}",
      {
        params: {
          path: { version: "1", supplierProductId },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch supplier product");
    }

    return {
      ...this.mapToSupplierProductHeader(data.data ?? {}),
      created: data.data.created ? new Date(data.data.created) : new Date(),
    };
  }

  async getProducts(
    supplierId: number,
    currentPosition: number,
    fetchNext: number,
    fetchAll: boolean
  ): Promise<ApplicationSupplierProductsList> {
    const { data, error } = await client.GET(
      "/v{version}/api/supplier/{supplierId}/supplierproduct",
      {
        params: {
          path: { version: "1", supplierId },
          query: {
            "Paging.CurrentPosition": currentPosition,
            "Paging.FetchNext": fetchNext,
            "Paging.FetchAll": fetchAll,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch supplier products");
    }

    return {
      products: this.mapToSupplierProductHeaders(data.data.products ?? []),
      loading: false,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async insertProductPrice(
    supplierProductId: number,
    price: number
  ): Promise<void> {
    const { data, error } = await client.POST(
      "/v{version}/api/supplier/supplierproduct/{supplierProductId}/price",
      {
        params: {
          path: { version: "1", supplierProductId },
        },
        body: {
          price,
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to insert product price");
    }
  }

  async updateProduct(
    product: ApplicationSupplierProductDetail
  ): Promise<ApplicationSupplierProductDetail> {
    const request = this.mapToSupplierProductDTO(product);

    const { data, error } = await client.PUT(
      "/v{version}/api/supplier/supplierproduct/{supplierProductId}",
      {
        params: {
          path: { version: "1", supplierProductId: product.id },
        },
        body: {
          ...request,
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to update supplier product");
    }

    return {
      ...this.mapToSupplierProductHeader(data.data ?? {}),
      created: data.data.created ? new Date(data.data.created) : new Date(),
    };
  }

  async insertProduct(
    product: ApplicationSupplierProductDetail
  ): Promise<ApplicationSupplierProductDetail> {
    const request = this.mapToSupplierProductDTO(product);

    const { data, error } = await client.POST(
      "/v{version}/api/supplier/supplierproduct",
      {
        params: {
          path: { version: "1" },
        },
        body: {
          ...request,
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to insert supplier product");
    }

    return {
      ...this.mapToSupplierProductHeader(data.data ?? {}),
      created: data.data.created ? new Date(data.data.created) : new Date(),
    };
  }

  async bulkUploadProducts(
    supplierId: number,
    products: ApplicationProductUpload[]
  ): Promise<ApplicationProductUpload | null> {

    const updatedProducts = products.map((product) => ({
      name: product.name,
      price: product.newPrice,
      size: product.newSize,
      sizeUnit: product.newSizeUnit == ApplicationSizeUnit.Unknown ? product.sizeunit : product.newSizeUnit,
    }));

    // Make the POST request
    const { data, error } = await client.POST(
      "/v{version}/api/supplier/supplierproduct/bulk",
      {
        params: {
          path: { version: "1" }, // API version
        },
        body: {
          supplierId,
          products: updatedProducts,
        },
      }
    );
    // Check for errors or unexpected response
    if (error) {
      throw new ApplicationError("Failed to bulk upload products");
    }
    if (!data?.data || !data?.success) {
      throw new ApplicationError("Unexpected response format");
    }
    // Return the response data
    return data.data as ApplicationProductUpload;
  }
  private mapToSupplierDetail(
    supplier: components["schemas"]["SupplierDTO"]
  ): ApplicationSupplierDetail {
    return {
      id: supplier.id ?? 0,
      name: supplier.name ?? "",
      noOfProducts: supplier.noOfProducts ?? 0,
      updated: supplier.updated ? new Date(supplier.updated) : new Date(),
      addressLine1: supplier.addressLine1 ?? "",
      addressLine2: supplier.addressLine2 ?? "",
      addressLine3: supplier.addressLine3 ?? "",
      city: supplier.city ?? "",
      postcode: supplier.postcode ?? "",
      phone: supplier.phone ?? "",
      email: supplier.email ?? "",
      apiIntegration: supplier.apiIntegration ?? false,
      whatsappIntegration: supplier.whatsappIntegration ?? false,
      emailIntegration: supplier.emailIntegration ?? false,
      departmentIds: supplier.departmentIds ?? [],
      deliveryDays:
        supplier.deliveryDays?.map((x) => mapStringToEnum(ApplicationDay, x)) ??
        [],
      orderCutOffTime: supplier.orderCutOffTime ?? "",
      branches: supplier.branches?.map((branch) => ({ id: branch?.id ?? 0, name: branch?.name ?? "" })) ?? []
    };
  }

  private mapToSupplierDTO(
    supplier: ApplicationSupplierDetail
  ): components["schemas"]["SupplierDTO"] {
    return {
      id: supplier?.id ?? 0,
      name: supplier.name,
      addressLine1: supplier.addressLine1,
      addressLine2: supplier.addressLine2,
      addressLine3: supplier.addressLine3,
      city: supplier.city,
      postcode: supplier.postcode,
      phone: supplier.phone,
      email: supplier.email,
      apiIntegration: supplier.apiIntegration,
      whatsappIntegration: supplier.whatsappIntegration,
      emailIntegration: supplier.emailIntegration,
      departmentIds: supplier.departmentIds,
      deliveryDays: supplier.deliveryDays,
      orderCutOffTime: supplier.orderCutOffTime,
      branches: supplier.branches,
    };
  }

  private mapToSupplierProductDTO(
    product: ApplicationSupplierProductDetail
  ): components["schemas"]["SupplierProductDTO"] {
    return {
      id: product.id ?? 0,
      supplierId: product.supplierId,
      productId: product.productId,
      name: product.name,
      size: product.size,
      sizeUnit: product.sizeUnit,
      active: product.active,
      price: product.price ?? 0,
    };
  }

  private mapToSupplierHeaders(
    suppliers: components["schemas"]["SupplierDTO"][] | undefined
  ): ApplicationSupplierHeader[] {
    return suppliers?.map(this.mapToSupplierHeader) || [];
  }

  private mapToSupplierHeader(
    supplier: components["schemas"]["SupplierDTO"]
  ): ApplicationSupplierHeader {
    return {
      id: supplier.id ?? 0,
      name: supplier.name ?? "",
      noOfProducts: supplier.noOfProducts ?? 0,
      addressLine1: supplier.addressLine1 ?? "",
      addressLine2: supplier.addressLine2 ?? "",
      addressLine3: supplier.addressLine3 ?? "",
      city: supplier.city ?? "",
      postcode: supplier.postcode ?? "",
      phone: supplier.phone ?? "",
      email: supplier.email ?? "",
      updated: supplier.updated ? new Date(supplier.updated) : new Date(),
      branches: supplier.branches?.map((branch) => ({ id: branch?.id ?? 0, name: branch?.name ?? "" })) ?? []
    };
  }

  private mapToSupplierProductHeaders(
    products: components["schemas"]["SupplierProductDTO"][] | undefined
  ): ApplicationSupplierProductHeader[] {
    return products?.map(this.mapToSupplierProductHeader) || [];
  }

  private mapToSupplierProductHeader(
    product: components["schemas"]["SupplierProductDTO"]
  ): ApplicationSupplierProductHeader {
    return {
      id: product.id ?? 0,
      supplierId: product.supplierId ?? 0,
      productId: product.productId ?? 0,
      name: product.name ?? "",
      description: product.description ?? "",
      image: product.image ?? "",
      plu: product.plu ?? "",
      size: product.size ?? 0,
      sizeUnit: mapStringToEnum(ApplicationSizeUnit, product.sizeUnit ?? "Unknown"),
      updated: product.updated ? new Date(product.updated) : new Date(),
      active: product.active ?? false,
      price: product.price ?? 0,
      editPrice: false,
      newPrice: product.price ?? 0,
    };
  }
}
