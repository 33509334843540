import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ApplicationError } from "../../../models/errors/application-error";
import SupplierService from "../../../services/supplier/supplier-service";
import { ApplicationSupplierProductsList } from "../../../models/supplier/application-supplier-product-list";
import { showError, showSuccess } from "../../../components/notification/toastr-actions";
import { ApplicationSupplierProductDetail } from "../../../models/supplier/application-supplier-product-detail";
import { ApplicationProductList } from "../../../models/product/application-product-list";
import ProductService from "../../../services/product/product-service";
import { ApplicationSupplierProductHeader } from "../../../models/supplier/application-supplier-product-header";


export const fetchSupplierProducts = createAsyncThunk<ApplicationSupplierProductsList | null, number, { state: RootState }>(
    'supplierProducts/get',
    async (supplierId, { getState, rejectWithValue }) => {
        const state = getState();
        const supplierService = new SupplierService();

        try {
            var currentPosition: number = state.supplierProducts?.products?.currentPosition ?? 0;
            var fetchNext: number = 15;
            var count: number = state.supplierProducts.products?.count ?? -1;

            if (currentPosition != count) {
                return await supplierService.getProducts(supplierId, currentPosition, fetchNext, false)
            }
            return null;
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const fetchSupplierProduct = createAsyncThunk<ApplicationSupplierProductDetail | null, number, { state: RootState }>(
    'supplierProduct/get',
    async (supplierProductId, { rejectWithValue }) => {
        const supplierService = new SupplierService();
        try {
            return await supplierService.getProduct(supplierProductId)
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const searchSupplierProducts = createAsyncThunk<ApplicationProductList | null, { supplierId: number, searchText: string, fetchAll: boolean }, { state: RootState }>(
    'supplierProducts/search',
    async ({ supplierId, searchText, fetchAll }, { getState, rejectWithValue }) => {
        const state = getState();
        const productService = new ProductService();
        try {
            var currentPosition: number = state.supplierProducts.searchProducts?.currentPosition ?? 0;
            var fetchNext: number = 20;
            var count: number = state.supplierProducts.searchProducts?.count ?? -1;

            if (currentPosition != count) {
                return await productService.searchSupplierProducts(supplierId, searchText, currentPosition, fetchNext, fetchAll);
            }

            return null;
        } catch (error: any) {
            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const mapProduct = createAsyncThunk<ApplicationSupplierProductDetail | null, number, { state: RootState }>(
    'supplierProduct/mapupdate',
    async (productId, { dispatch, getState, rejectWithValue }) => {
        const supplierService = new SupplierService();
        try {
            const supplierProductState = getState().supplierProducts;
            if (supplierProductState.selectedUnmappedProduct) {
                var product = await supplierService.getProduct(supplierProductState.selectedUnmappedProduct.id);
                product.productId = productId;
                return await supplierService.updateProduct(product)
            }

            dispatch(showError("No product selected to map"))
            return null;
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const updateSupplierProduct = createAsyncThunk<ApplicationSupplierProductDetail | null, ApplicationSupplierProductDetail, { state: RootState }>(
    'supplierProduct/update',
    async (product, { getState, rejectWithValue }) => {
        const supplierService = new SupplierService();
        try {
            return await supplierService.updateProduct(product)
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

export const insertSupplierProduct = createAsyncThunk<ApplicationSupplierProductDetail | null, ApplicationSupplierProductDetail, { state: RootState }>(
    'supplierProduct/insert',
    async (product, { getState, rejectWithValue }) => {
        const supplierService = new SupplierService();
        try {
            return await supplierService.insertProduct(product)
        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);


export const insertSupplierProductPrice = createAsyncThunk<{ id: number, price: number } | null, number, { state: RootState }>(
    'supplierProductsPrice/insert',
    async (supplierProductId, { getState, dispatch, rejectWithValue }) => {

        const supplierService = new SupplierService();
        try {

            const state = getState();
            var product = state.supplierProducts?.products?.products.find(x => x.id == supplierProductId);

            if (!product) {
                throw new ApplicationError("No product found");
            }
            await supplierService.insertProductPrice(supplierProductId, product.newPrice);
            dispatch(showSuccess("Price updated"))
            return { id: supplierProductId, price: product.newPrice }

        } catch (error: any) {

            const apiError = ApplicationError.handleApiError(error, {});
            return rejectWithValue(apiError);
        }
    }
);

interface SupplierProductState {
    products: ApplicationSupplierProductsList | null
    selectedUnmappedProduct: ApplicationSupplierProductHeader | null
    loading: boolean,
    searchProducts: ApplicationProductList | null
}

const initialState: SupplierProductState = {
    products: null,
    selectedUnmappedProduct: null,
    loading: false,
    searchProducts: null,
};

const supplierProductSlice = createSlice({
    name: 'supplierProducts',
    initialState,
    reducers: {
        resetSupplierProducts: (state) => {
            state.products = initialState.products;
        },
        resetSearchProducts: (state) => {
            state.searchProducts = initialState.searchProducts
        },
        resetUnmappedSelectedProduct: (state) => {
            state.selectedUnmappedProduct = initialState.selectedUnmappedProduct
        },
        setUnmappedSelectedProduct: (state, action: PayloadAction<ApplicationSupplierProductHeader>) => {
            state.selectedUnmappedProduct = action.payload;
        },
        setMapping: (state, action: PayloadAction<number>) => {
            if (state.selectedUnmappedProduct) {
                state.selectedUnmappedProduct.productId = action.payload;
            }
        },
        toggleEditPrice: (state, action: PayloadAction<number>) => {
            var product = state.products?.products.find(x => x.id == action.payload);
            if (product) {
                product.editPrice = !product.editPrice
            }
        },
        setNewPrice: (state, action: PayloadAction<{ id: number, price: number }>) => {
            var product = state.products?.products.find(x => x.id == action.payload.id);
            if (product) {
                product.newPrice = action.payload.price;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSupplierProducts.fulfilled, (state, action: PayloadAction<ApplicationSupplierProductsList | null>) => {
                if (action.payload) {
                    if (state.products?.products) {
                        state.products.products = state.products.products.concat(action.payload!.products);
                        state.products.currentPosition = action.payload!.currentPosition
                        state.products.count = action.payload!.count;
                    } else {
                        state.products = action.payload;
                    }
                }
            })
            .addCase(insertSupplierProductPrice.fulfilled, (state, action: PayloadAction<{ id: number, price: number } | null>) => {
                if (action.payload) {
                    var product = state.products?.products.find(x => x.id == action.payload?.id);
                    if (product) {
                        product.newPrice = 0;
                        product.price = action.payload.price;
                        product.editPrice = false;
                    }
                }
            })
            .addCase(searchSupplierProducts.fulfilled, handleSearchProductsFulfilled)
            .addCase(updateSupplierProduct.fulfilled, handleUpdateProduct)
            .addCase(mapProduct.fulfilled, handleUpdateProduct)
            .addCase(insertSupplierProduct.fulfilled, handleInsertProduct)

    },
});


const handleInsertProduct = (
    state: SupplierProductState,
    action: PayloadAction<ApplicationSupplierProductDetail | null>
) => {
    if (action.payload && state.products?.products) {
        const insertedProduct = action.payload;
        state.products.products.push(mapToSupplierProductHeader(insertedProduct));
        state.products.count += 1;
    }
};

const handleUpdateProduct = (
    state: SupplierProductState,
    action: PayloadAction<ApplicationSupplierProductDetail | null>
) => {
    if (action.payload && state.products?.products) {
        const updatedProduct = action.payload;

        state.products.products = state.products.products.map(product =>
            product.id === updatedProduct.id ?
                mapToSupplierProductHeader(updatedProduct) : product
        );
    }
};

const handleSearchProductsFulfilled = (
    state: SupplierProductState,
    action: PayloadAction<ApplicationProductList | null>
) => {
    if (action.payload) {
        const { products, currentPosition, count } = action.payload;
        if (state.searchProducts) {
            state.searchProducts.products = state.searchProducts.products.concat(products);
            state.searchProducts.currentPosition = currentPosition;
            state.searchProducts.count = count;
        } else {
            state.searchProducts = action.payload;
        }
    }
};

const mapToSupplierProductHeader = (model: ApplicationSupplierProductDetail) => {
    return {
        id: model.id,
        supplierId: model.supplierId,
        name: model.name,
        productId: model.productId,
        description: model.description,
        image: model.image,
        size: model.size,
        sizeUnit: model.sizeUnit,
        plu: model.plu,
        updated: model.updated,
        active: model.active,
        price: model.price,
        editPrice: false,
        newPrice: model.price,
    } as ApplicationSupplierProductHeader
}

export const { resetSupplierProducts, toggleEditPrice, setNewPrice, resetSearchProducts, resetUnmappedSelectedProduct, setUnmappedSelectedProduct, setMapping } = supplierProductSlice.actions;
export default supplierProductSlice.reducer;
