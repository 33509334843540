import React from 'react';
import styled from 'styled-components';
import { GoogleIcon, ICON_ID } from '../icons/google-icon';
import { ApplicationProductList } from '../../models/product/application-product-list';
import Image from './image';
import { SmallTextContainer } from '../../styles/shared/table';
import { blurAnimation } from '../../styles/shared/loading';
interface ProductListProps {
    products: ApplicationProductList | undefined
    selectedProductIds: number[] | null | undefined
    categoryId: number,
    onClick: (id: number, categoryId: number, productName: string) => void
    loadingRowRef?: React.RefObject<HTMLDivElement>
}

const ProductList: React.FC<ProductListProps> = ({ products, onClick, selectedProductIds, categoryId, loadingRowRef }) => {
    const getIcon = (productId: number) => {
        if (selectedProductIds) {
            const selectedItem = selectedProductIds.find(x => x == productId);
            if (selectedItem) {
                return <GoogleIcon isSuccess={true} id={ICON_ID.CheckCircle}></GoogleIcon>
            }
        }
        return <GoogleIcon isWarning={true} id={ICON_ID.AddCircle}></GoogleIcon>
    }

    const placeholdersCount = (products?.count ?? 0) - (products?.currentPosition ?? 0);

    return (
        <Products>
            {products?.products?.map((product) => (
                <Product key={`product_${product.id}`} onClick={() => onClick(product.id, categoryId, product.name)}>
                    <ProductImage>
                        <Image image={product.image}></Image>
                        {getIcon(product.id)}
                    </ProductImage>
                    <ProductTitle>{product.name}</ProductTitle>
                    <SmallTextContainer>Plu: {product.plu}</SmallTextContainer>
                </Product>
            ))}

            {placeholdersCount > 0 && (
                <LoadingRow ref={loadingRowRef}>
                    <LoadingCell></LoadingCell>
                    <LoadingCell></LoadingCell>
                    <LoadingCell></LoadingCell>
                    <LoadingCell></LoadingCell>
                    <LoadingCell></LoadingCell>
                </LoadingRow>

            )}
        </Products>
    );
};

export default ProductList;

const Products = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    width: 95%;
    margin: 20px auto;
    
`;

const Product = styled.div`
    cursor: pointer;
    margin-bottom:20px;
    width:150px;
`;

const ProductImage = styled.div`
    width: 80px;
    height: 80px;    
    border-radius: 10px;  
    position: relative;

    span {        
        position: absolute;      
        bottom:0px;  
        left: 68px;
        font-size: 1.2rem;
    }
`;

const ProductTitle = styled.div`
    font-weight: 500;
`;

const ProductSize = styled.div`
    font-size: 0.8rem;
`;


const LoadingRow = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 4 equal columns */
  gap: 10px;
  padding: 10px 0;
  animation: ${blurAnimation} 0.5s infinite;
   height: 50px;

`;

const LoadingCell = styled.div` 
  padding: 20px;
  border-radius: 15px;  
  padding: 20px;
  border-radius: 6px;
  background:  ${(props) => props.theme.application.placeholder};
  margin: 0px 16px;
  width: 39px;
    height: 28px;
`;
