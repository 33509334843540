import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./features/theming/theme-selector-slice";
import loginReducer from "./features/authentication/login-slice";
import userReducer from "./features/authentication/user-slice";
import departmentReducer from "./features/organisation/department-slice";
import errorReducer from "./features/notification/error-slice";
import homeReducer from "./features/pages/home-page-slice";
import productReducer from "./features/product/product-slice";
import modalReducer from "./features/modal/modal-slice";
import basketReducer from "./features/ordering/basket/basket-slice";
import toastrReducer from "./features/notification/toastr-slice";
import continousProductReducer from "./features/product/continous-product-slice";
import orderReducer from "./features/ordering/order-slice";
import branchReducer from "./features/organisation/branch-slice";
import errorHandlingMiddleware from "./middleware/error-handling-middleware";
import confirmReducer from "./features/notification/confirm-slice";
import approvalReducer from "./features/ordering/approval/approval-slice";
import supplierReducer from "./features/supplier/supplier-slice";
import supplierProductsReducer from "./features/supplier/supplier-product-slice";
import supplierProductUploadReducer from "./features/supplier/supplier-product-upload-slice";
import adminDepartmentReducer from "./features/organisation/admin-department-slice";
export const store = configureStore({
  reducer: {
    user: userReducer,
    themeSelector: themeReducer,
    login: loginReducer,
    department: departmentReducer,
    error: errorReducer,
    home: homeReducer,
    product: productReducer,
    modal: modalReducer,
    confirm: confirmReducer,
    basket: basketReducer,
    toastr: toastrReducer,
    order: orderReducer,
    branch: branchReducer,
    approval: approvalReducer,
    continousProduct: continousProductReducer,
    supplier: supplierReducer,
    supplierProducts: supplierProductsReducer,
    supplierProductUpload: supplierProductUploadReducer,
    adminDepartment: adminDepartmentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorHandlingMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
