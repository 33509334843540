import { Outlet, Navigate, useParams } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { ApplicationRole } from '../../models/authentication/application-roles';
import OwnerLayout from '../../pages/layouts/owner-layout';
import SupplierLayout from '../../pages/layouts/supplier-layout';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { fetchSupplier, resetSelectedSupplier, resetSuppliers } from '../../store/features/supplier/supplier-slice';

export const SupplierRoute = () => {
    const { initialized, loading, authenticated, role } = useAuth();
    const { supplierId } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (supplierId) {
            const parsedsupplierId = parseInt(supplierId);
            dispatch(fetchSupplier(parsedsupplierId));
        } else {
            dispatch(resetSelectedSupplier());
        }

    }, [supplierId])

    if (!initialized || loading) {
        return <div>Loading...</div>;
    }

    if (!authenticated) {
        return <Navigate to="/login" replace />;
    }

    if (role != ApplicationRole.SupplierManager && role != ApplicationRole.Owner) {
        return <Navigate to="/home" replace />;
    }

    return (
        <SupplierLayout>
            <Outlet />
        </SupplierLayout>
    )
};