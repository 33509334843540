import { ApplicationError } from "../../models/errors/application-error";
import IDepartmentService from "./department-service-interface";
import { ApplicationDepartment } from "../../models/organisation/application-department";
import { ApplicationDepartmentCategoryList } from "../../models/organisation/application-department-category-list";
import client from "../../external/open-api";
import { ApplicationDepartmentCategory } from "../../models/organisation/application-department-category";
import { EditApplicationDepartment } from "../../models/organisation/edit-application-department";
export default class DepartmentService implements IDepartmentService {
  async getDepartmentById(
    departmentId: number
  ): Promise<EditApplicationDepartment> {
    const { data, error } = await client.GET(
      "/v{version}/api/department/{departmentId}",
      {
        params: {
          path: { version: "1", departmentId }, // Path parameter
        },
      }
    );
    if (!data?.data || error || !data.success) {
      throw new ApplicationError(
        `Failed to fetch department with ID ${departmentId}`
      );
    }
    const departmentCategories: ApplicationDepartmentCategory[] =
      data.data.departmentCategories?.map((category: any) => ({
        id: category.id ?? 0,
        departmentId: category.departmentId ?? 0,
        description: category.description ?? "",
        name: category.name ?? "",
      })) || [];
    return {
      id: data.data.id ?? 0,
      name: data.data.name ?? "",
      productSelectionMode: data.data.productSelectionMode ?? "",
      friendlyUrl: generateFriendlyUrl(data.data.name ?? "", data.data.id ?? 0),
      departmentCategories:
        departmentCategories.length > 0 ? departmentCategories : undefined,
    };
  }

  async updateDepartment(
    department: ApplicationDepartment
  ): Promise<ApplicationDepartment> {
    const { data, error } = await client.PUT(
      "/v{version}/api/department/{departmentId}",
      {
        params: {
          path: { version: "1", departmentId: department.id }, // Path parameter
        },
        body: {
          ...department,
        },
      }
    );

    if (!data?.data || error || !data.success) {
      throw new ApplicationError(
        `Failed to update department with ID ${department.id}`
      );
    }
    // Return the updated department
    return data.data as unknown as ApplicationDepartment;
  }

  async getCategories(
    departmentId: number
  ): Promise<ApplicationDepartmentCategoryList> {
    const { data, error } = await client.GET(
      "/v{version}/api/department/{departmentId}/categories",
      {
        params: {
          path: { version: "1", departmentId }, // Path parameter
          query: {
            "Paging.CurrentPosition": 0,
            "Paging.FetchNext": 0,
            "Paging.FetchAll": true,
          },
        },
      }
    );

    if (!data?.data || !data?.success || error) {
      throw new ApplicationError("Failed to fetch department categories");
    }

    var departmentCategories =
      data.data?.departmentCategories?.map((d) => ({
        id: d.id ?? 0,
        departmentId: d.departmentId ?? 0,
        name: d.name ?? "",
        description: d.description ?? "",
      })) || [];

    return {
      departmentCategories,
      count: data.data?.count || 0,
      currentPosition: data.data?.currentPosition || 0,
    };
  }

  async get(): Promise<ApplicationDepartment[]> {
    const { data, error } = await client.GET("/v{version}/api/department", {
      params: {
        path: { version: "1" },
        query: {
          "Paging.CurrentPosition": 0,
          "Paging.FetchNext": 0,
          "Paging.FetchAll": true,
        },
      },
    });

    if (!data?.data || error || !data.success) {
      throw new ApplicationError("Failed to fetch departments");
    }

    return (
      data.data.departments?.map((d) => ({
        id: d.id ?? 0,
        name: d.name ?? "",
        productSelectionMode: d.productSelectionMode ?? "",
        friendlyUrl: generateFriendlyUrl(d.name ?? "", d.id ?? 0) ?? "",
      })) || []
    );
  }
}

const generateFriendlyUrl = (name: string, id: number): string => {
  let friendlyName = name
    .trim()
    .replace(/\s+/g, "-")
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, "");

  return `${friendlyName}-${id}`;
};
