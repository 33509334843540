import React, { useEffect } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import Header from '../../components/layout/header';
import { DepartmentSidebar } from '../../components/layout/department-sidebar';
import { fetchDepartments } from '../../store/features/organisation/department-slice';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { ApplicationDepartment } from '../../models/organisation/application-department';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useBasket } from '../../hooks/useBasket';
import Toastr from '../../components/notification/toastr';
import { useErrorDisplay } from '../../hooks/useErrorDisplay';
import { setSelectedBranch } from '../../store/features/organisation/branch-slice';
import { ApplicationBranch } from '../../models/organisation/application-branch';
import { resetBasket } from '../../store/features/ordering/basket/basket-slice';
import { resetActiveOrders } from '../../store/features/ordering/order-slice';
import { ModalID } from '../../constants/modal-constants';
import { closeModal, openModal } from '../../store/features/modal/modal-slice';
import ModalHandler from '../modals/modal-handler';
import ConfirmModal from '../../components/notification/confirm-modal';
import { Content, Main } from '../../styles/shared/page';
import Button from '../../components/controls/button';
import SplitButton, { SplitOptions } from '../../components/controls/split-button';
import { ApplicationRole } from '../../models/authentication/application-roles';


interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const user = useAppSelector((state => state.user)).user;
  const branch = useAppSelector((state => state.branch)).selectedBranch;
  const departments = useAppSelector((state => state.department)).departments;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const basket = useBasket();

  useErrorDisplay();

  useEffect(() => {
    dispatch(fetchDepartments())
  }, [])

  const selectDepartment = (department: ApplicationDepartment) => {
    if (department.productSelectionMode.toLowerCase() == "continous") {
      dispatch(openModal({
        id: ModalID.ContinousProduct,
        data: { departmentId: department.id, branchId: branch?.id }
      }));
    } else {
      dispatch(closeModal());
      navigate(`${department.friendlyUrl}/products`)
    }
  }

  const selectBranch = (branch: ApplicationBranch) => {
    dispatch(setSelectedBranch(branch))
    dispatch(resetBasket());
    dispatch(resetActiveOrders());
    sessionStorage.setItem("branch", branch.id.toString())
  }

  const BuildSplitOptions = () => {

    let options = departments.map(x => ({
      key: x.id.toString(),
      value: x.name,
      onClick: () => selectDepartment(x)
    }));

    if (user?.role == ApplicationRole.Owner) {
      options.push({
        key: "OwnerPortal",
        value: "Owner Portal",
        onClick: () => navigate("admin"),
      });
    }

    return options;
  }

  return (
    <div className="layout">
      <Toastr></Toastr>
      <ModalHandler></ModalHandler>
      <ConfirmModal></ConfirmModal>
      <Header basket={basket} name={user?.name} team={user?.role} branches={user?.branches} selectedBranch={branch} onBranchChange={selectBranch} />
      <Content>
        <DepartmentSidebar user={user!} departments={departments} onDepartmentClick={selectDepartment}></DepartmentSidebar>
        <Main>
          <OrderButton>
            <SplitButton id="btnOrder" label='Order' variant='contained' fullWidth={true} options={BuildSplitOptions()}></SplitButton>
          </OrderButton>
          {children}
        </Main>
      </Content>
    </div>
  );
};

export default Layout;


const OrderButton = styled.div`
  padding:10px;

      @media (min-width: 760px) {
        display:none;
    }
`