import styled from "styled-components";

interface ScrollableCardProps {
  background: string;
  scrollbar: string;
}
interface ScrollableListProps {
  maxHeight?: string;
}
export const ScrollableCard = styled.div<ScrollableCardProps>`
  max-height: 100%;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: auto;
  background: ${(props) => props.background};
  border-radius: 3px;
  position: relative;

  &::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.background};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.scrollbar};
    border: 2px solid ${(props) => props.scrollbar};
    border-radius: 4px;
  }
`;

export const FeatureCard = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${(props) => props.theme.application.border};
  padding-bottom: 15px;
  margin-bottom: 10px;
`;

export const FeatureCardTitle = styled.div`
  font-weight: 500;
  margin: 5px 10px;
`;

export const FeatureCardRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;

  @media (min-width: 700px) {
  flex-direction: row;
    align-items: baseline;
  }

`;

export const FeatureCardColumn = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width:90%;

    @media (min-width: 760px) {
      width: 44%;
    }

  div.bold {
    font-weight: 600;
  }
`;

export const FeatureCardSingleColumn = styled.div`
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;

  div.bold {
    font-weight: 600;
  }
`;
export const ScrollableList = styled.div<ScrollableListProps>`
  max-height: ${(props) => props.maxHeight || "400px"};
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  border-radius: 3px;
  position: relative;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
    border: 1px solid #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
`;
